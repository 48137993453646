const BillboardMap = () => import(/* webpackChunkName: "SovMap" */ '../views/BillboardMap');
const BillboardList = () => import('../views/BillboardMap/PlacementList');
const ViewPlacement = () => import('../views/BillboardMap/EditPlacement');
const CreatePlacement = () => import('../views/BillboardMap/CreatePlacement');
const BulkEdit = () => import('../views/BillboardMap/BulkEdit');
const BulkCreate = () => import('../views/BillboardMap/BulkCreate');

const billboardMapRouter = [
    {
      path: '/billboard-map',
      name: 'billboardmap',
      component: BillboardMap,
      meta: { isLinkAja: true }
    },
    {
      path: '/billboard-list',
      name: 'billboardlist',
      component: BillboardList,
      meta: { isLinkAja: true }
    },
    {
      path: '/view-placement/:id',
      name: 'viewplacement',
      component: ViewPlacement,
      meta: { isLinkAja: true }
    },
    {
      path: '/create-placement',
      name: 'createplacement',
      component: CreatePlacement,
      meta: { isLinkAja: true }
    },
    {
      path: '/bulk-edit',
      name: 'bulkedit',
      component: BulkEdit,
      meta: { isLinkAja: true }
    },
    {
      path: '/bulk-create',
      name: 'bulkcreate',
      component: BulkCreate,
      meta: { isLinkAja: true }
    }
]

export default billboardMapRouter;
